import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import { setCookies } from './redux/localSlice';

import Landing from './page/Landing';
import Terms from './page/Terms';
import Privacy from './page/Privacy';
import ContactUs from './page/ContactUs';
import BookDemo from './page/BookDemo';

import LogRocket from 'logrocket';
import posthog from 'posthog-js';
import CookiesBanner from './component/block/CookiesBanner';
import CookieSettings from './component/block/CookieSettings';
import TermsEmbed from './page/TermsEmbed';

function App() {
  const dispatch = useDispatch();
  const local = useSelector((state) => state.local);
  const timeoutRef = useRef(null);
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [cookieModalVisible, setCookieModalVisible] = useState(false);

  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  const isLocalhost = window.location.origin.includes('localhost') ? true : false;
  const isBotTraffic = /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/12.*Safari\/537\.36$/.test(navigator.userAgent);

  const initAnalytics = () => {
    const scriptOne = document.createElement("script");
    scriptOne.src = "https://www.googletagmanager.com/gtag/js?id=G-F9HDQ6FYX5";
    scriptOne.async = true;
    document.head.appendChild(scriptOne);

    const scriptTwo = document.createElement("script");
    scriptTwo.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-F9HDQ6FYX5');
    `;
    document.head.appendChild(scriptTwo);

    LogRocket.init('o4yyvv/materialize-landing');

    posthog.init('phc_NGGCjf8a2ZLnMRrPhBqOI0pD9UbnTQklPmSsuGNxzEb',
        {
            api_host: 'https://us.i.posthog.com',
            person_profiles: 'always' // or 'identified_only' to not create profiles for anonymous users
        }
    );
  }

  if (!isLocalhost && !isBotTraffic && (local?.cookies || local?.cookies === "all")) {
    initAnalytics();
  }

  const handleShowCookieModal = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setCookieModalVisible(true);
    setShowCookieModal(true);
    const landing = document.getElementById('landing');
    if (landing) {
      landing.classList.add('overflow-y-hidden');
    }
  };

  const handleHideCookieModal = () => {
    setShowCookieModal(false);    
    timeoutRef.current = setTimeout(() => {
      setCookieModalVisible(false);
      const landing = document.getElementById('landing');
      if (landing) {
        landing.classList.remove('overflow-y-hidden');
      }
    }, 700);
  };

  const handleSetCookies = (setting) => {
      if (setting === "all" && !isLocalhost && !isBotTraffic) {
          initAnalytics();
          handleHideCookieModal();
      }

      dispatch(setCookies(setting));
  };

  return (
    <Router>
      <Routes>
        <Route key={"/"} path={"/"} element={
          <Landing
            handleSetCookies={handleSetCookies}
          />
        } />
        <Route key={"/contact-us"} path={"/contact-us"} element={
          <ContactUs />
        } />
        <Route key={"/book-demo"} path={"/book-demo"} element={
          <BookDemo />
        } />
        <Route key={"/terms-of-use"} path={"/terms-of-use"} element={<Terms />} />
        <Route key={"/terms-of-use-embed"} path={"/terms-of-use-embed"} element={<TermsEmbed />} />
        <Route key={"/privacy-policy"} path={"/privacy-policy"} element={<Privacy />} />
        <Route key={"/:page"} path={"/:page"} element={<Landing />} />
      </Routes>
      {cookieModalVisible && <CookieSettings handleSetCookies={handleSetCookies} showCookieModal={showCookieModal} handleHideCookieModal={handleHideCookieModal} classes={`${showCookieModal ? "fade-in" : "fade-out no-pointer"}`} />}
      {(local?.cookies === "unset" && window.location.pathname !== "/terms-of-use-embed") && (
        <CookiesBanner
          handleShowCookieModal={handleShowCookieModal}
          handleSetCookies={handleSetCookies}
        />
      )}
    </Router>
  );
}

export default App;