import './style/Select.css';
import React from 'react';
import { } from '../../redux/localSlice';

const Select = ({ options, value, onChange, onWhite = true, onGrey = false, classes = "" }) => {
    return (
        <select className={`keystone-select sf-mono size-m line-m ${(onWhite ? " on-white" : "") } ${onGrey ? " on-grey" : ""} ${classes}`} value={value} onChange={onChange}>
            <option value="" disabled selected>Select</option>
            {options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
    );
};

export default Select;