import './style/SiteFooter.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LinkedIn from '../../media/linkedin_logo_24.png';
import Mail from '../../media/mail_logo_24.png';
import WordmarkBlack from '../../media/materialize-logo-wordmark-black.png';
import WordmarkWhite from '../../media/materialize-logo-wordmark-white.png';
import X from '../../media/x_logo_24.png';
import { } from '../../redux/localSlice';
import { } from '../../redux/temporarySlice';
import Divider from './Divider';
import Icon from './Icon';
import Label from './Label';
import LinkButton from './LinkButton';
import Section from './Section';

const SiteFooter = ({ bgColor = "grey", bumpForCookieFooter = true, handleShowSignupModal }) => {
    const local = useSelector((state) => state.local);
    const navigate = useNavigate();

    const secondaryText = bgColor === "grey" ? true : false;
    const textColor = bgColor === "black" ? "white-text-transparent" : "secondary";
    const iconColor = bgColor === "grey" ? "on-grey-icon" : "on-black-icon";
    const logo = bgColor === "black" ? WordmarkWhite : WordmarkBlack;

    const linkButton = (onClick, text) => {
        return (
            <LinkButton
                className={`footer on-${bgColor} round-s poppins semi-bold size-m line-m ${textColor}`}
                onClick={onClick}>
                {text}
            </LinkButton>
        )
    };

    const handleGoToApp = () => {
        window.location.href = "https://app.trymaterialize.com";
    };

    const handleGoToShop = () => {
        window.location.href = "https://shop.trymaterialize.com";
    };

    const handleGoToContactUs = () => {
        navigate("/contact-us");
    };

    const handleGoToTermsOfUse = () => {
        window.location.href = "https://www.trymaterialize.com/terms-of-use";
    };

    const handleGoToPrivacyPolicy = () => {
        window.location.href = "https://www.trymaterialize.com/privacy-policy";
    };

    return (
        <Section
            bg={bgColor}
            content={
                <div className={`flex vertical flex-1 v-gap-xl pad-xl no-pad-top no-pad-bot ${(local?.cookies === "unset" && bumpForCookieFooter) ? "cookie-banner-bump" : ""}`}>
                    <div className="flex horizontal mobile-vertical v-gap-m space-between h-gap-xl">
                        <div className="flex vertical flex-1">
                            <img src={logo} alt="Materialize Wordmark" className="wordmark-image" onClick={() => navigate("/")} />
                        </div>
                        <div className="flex horizontal flex-1 justify-end mobile-justify-start h-gap-display">
                            <div className="flex vertical">
                                <div className="flex vertical mobile-align-start justify-center" style={{height: "36px", paddingLeft: "6px", paddingRight: "6px"}}>
                                    <Label
                                        mono={true}
                                        content="Platform"
                                        uppercase={true}
                                        secondary={secondaryText}
                                        semiBold={true}
                                        size="xs"
                                        color={textColor}
                                    />
                                </div>
                                <div className="flex vertical align-start mobile-align-start v-gap-xs">
                                    {linkButton(handleGoToApp, "Client Login")}
                                    {linkButton(handleShowSignupModal, "Manufacturer Signup")}
                                    {linkButton(handleGoToShop, "Shop Certified Excess")}
                                </div>
                            </div>
                            <div className="flex vertical">
                                <div className="flex vertical mobile-align-start justify-center" style={{height: "36px", paddingLeft: "6px", paddingRight: "6px"}}>
                                    <Label
                                        mono={true}
                                        content="Company"
                                        uppercase={true}
                                        secondary={secondaryText}
                                        semiBold={true}
                                        size="xs"
                                        color={textColor}
                                    />
                                </div>
                                <div className="flex vertical align-start mobile-align-start v-gap-xs">
                                    {linkButton(handleGoToContactUs, "Contact Us")}
                                    {linkButton(handleGoToTermsOfUse, "Terms of Use")}
                                    {linkButton(handleGoToPrivacyPolicy, "Privacy Policy")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider classes={`${bgColor === "black" ? "on-black-divider" : ""}`} />
                    <div className="flex horizontal space-between">
                        <div className="flex vertical">
                            <Label
                                mono={true}
                                content="Materialize (Harper AI, Inc.)"
                                uppercase={true}
                                secondary={secondaryText}
                                color={textColor}
                                size="xs"
                                line="xs"
                            />
                            <Label
                                mono={true}
                                content="1288 McAllister St - San Francisco, CA 94115"
                                uppercase={true}
                                secondary={secondaryText}
                                color={textColor}
                                size="xs"
                                line="xs"
                            />
                        </div>
                        <div className="flex horizontal h-gap-m">
                            <Icon
                                icon={X}
                                size="28"
                                color="var(--ks-blue-dark)"
                                classes={iconColor}
                                onClick={() => window.open("https://x.com/trymaterialize", "_blank")} />
                            <Icon
                                icon={LinkedIn}
                                size="28"
                                color="var(--ks-blue-dark)"
                                classes={iconColor}
                                onClick={() => window.open("https://www.linkedin.com/company/trymaterialize", "_blank")} />
                            <Icon
                                icon={Mail}
                                size="28"
                                color="var(--ks-blue-dark)"
                                classes={iconColor}
                                onClick={() => window.location.href = "mailto:info@trymaterialize.com"} />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default SiteFooter;