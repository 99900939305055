import './style/LinkButton.css';
import React from 'react';

const LinkButton = ({className, onClick, children, link}) => {
    return (
        <>
            <a href={link} className = 'invisible'>{children}</a>
            <button className={`link-button ${className}`} onClick={onClick}>
                {children}
            </button>
        </>
    );
};

export default LinkButton;