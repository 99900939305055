import './style/TextArea.css';
import React from 'react';
import { } from '../../redux/localSlice';
import { } from '../../redux/temporarySlice';

const TextArea = ({ placeholder, type = "text", width100 = false, value, onChange, inputRef, flex, large = false, onBrand, bold = false, onWhite = true, alignRight = false, onFocus = null, onBlur = null, classes = "" }) => {
    return (
        <textarea type={type} className={`text-area sf-mono size-m ${bold ? " bold" : " medium"}${flex ? " flex" : ""}${alignRight ? " align-right" : ""}${large ? " large" : " small"}${onBrand ? " on-brand" : ""}${onWhite ? " on-white" : ""}${width100 ? " width-100" : ""} ${classes}`} placeholder={placeholder} onChange={onChange} onFocus={onFocus} onBlur={onBlur} value={value} ref={inputRef}>
        </textarea>
    );
};

export default TextArea;