import './style/BookDemo.css';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { } from '../redux/localSlice';
import { } from '../redux/temporarySlice';
import SiteHeader from '../component/block/SiteHeader';
import SiteFooter from '../component/block/SiteFooter';
import Signup from '../component/block/Signup';
import Label from '../component/block/Label';
import LabelInput from '../component/block/LabelInput';
import TextArea from '../component/block/TextArea';
import ReCAPTCHA from 'react-google-recaptcha';

import CursorClick from '../media/cursor_click_24.png';
import EnvelopeOpenFill from '../media/envelope_open_fill_48.png';
import Button from '../component/block/Button';

import { demoRequest } from '../redux/temporarySlice';
import SimpleLoader from '../component/block/SimpleLoader';
import Icon from '../component/block/Icon';
import Select from '../component/block/Select';

const ContactUs = () => {
    const local = useSelector((state) => state.local);
    const sitekey = window.location?.href?.includes('localhost') ? '6LeRb4AqAAAAAMi0AgNFSMFxyw9bo5q2wePZ9p3u' : '6LeVWn8qAAAAAHHCHAzhrmTQjuadBu9fzePxRzyo'
    const dispatch = useDispatch();
    const demoRequestProcessing = useSelector((state) => state.temporary.demoRequestProcessing);
    const demoRequestProcessed = useSelector((state) => state.temporary.demoRequestProcessed);

    const [modalVisible, setModalVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");

    const [errorsVisible, setErrorsVisible] = useState(false);
    const [errorShake, setErrorShake] = useState(false);

    const validateEmail = (email) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g.test(email);
    };
    
    const companySizes = [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+"
    ];

    const excessSizes = [
        "Less than $500,000",
        "$500,000 - $1,000,000",
        "$1,000,000 - $5,000,000",
        "$5,000,000 - $10,000,000",
        "Over $10,000,000",
        "Unknown"
    ];

    const [requestInfo, setRequestInfo] = useState({
        fullName: "",
        email: "",
        company: "",
        details: "",
        companySize: "",
        excessSize: ""
    });

    const handleFullNameChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.fullName = e.target.value;
        setRequestInfo(modifiedRequestInfo);
    };

    const handleEmailChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.email = e.target.value.replace(/\s/g, '');;
        setRequestInfo(modifiedRequestInfo);
    };

    const handleCompanyChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.company = e.target.value;
        setRequestInfo(modifiedRequestInfo);
    };

    const handleDetailsChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.details = e.target.value;
        setRequestInfo(modifiedRequestInfo);
    };

    const handleCompanySizeChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.companySize = e.target.value;
        setRequestInfo(modifiedRequestInfo);
    };

    const handleExcessSizeChange = (e) => {
        const modifiedRequestInfo = { ...requestInfo };
        modifiedRequestInfo.excessSize = e.target.value;
        setRequestInfo(modifiedRequestInfo);
    }

    const timeoutRef = useRef(null);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    const handleShowSignupModal = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        setModalVisible(true);
        setShowModal(true);

        const viewToLock = document.getElementById('book-demo');
        if (viewToLock) {
            viewToLock.classList.add('overflow-y-hidden');
        }
    };

    const handleHideSignupModal = () => {
        setShowModal(false);    
        timeoutRef.current = setTimeout(() => {
          setModalVisible(false);
          const viewToLock = document.getElementById('book-demo');
          if (viewToLock) {
            viewToLock.classList.remove('overflow-y-hidden');
          }
        }, 700);
    };

    const handleSubmitContactRequest = () => {
        if (requestInfo?.fullName === '' ||
            requestInfo?.email === '' ||
            !validateEmail(requestInfo?.email) ||
            requestInfo?.company === '' ||
            requestInfo?.companySize === '' ||
            requestInfo?.excessSize === '' ||
            !captchaComplete) {
            setErrorsVisible(true);
            setErrorShake(true);
            setTimeout(() => {
                setErrorShake(false);
            }, 1000);
            return;
        } else {
            setErrorsVisible(false);
            let request = {
                recaptcha_response: captchaResponse,
                email: requestInfo.email,
                name: requestInfo.fullName,
                company_name: requestInfo.company,
                metadata: {
                    request_type: "BOOK DEMO FORM",
                    company_size: requestInfo.companySize,
                    excess_size: requestInfo.excessSize,
                    details: requestInfo.details
                }
            }
    
            dispatch(demoRequest(request));
        }
    };

    return (
        <>
            {modalVisible && <Signup handleHideModal={handleHideSignupModal} showModal={showModal} classes={`${showModal ? "fade-in" : "fade-out no-pointer"}`} />}
            <div id="book-demo" className="fill-viewport overflow-y-scroll flex vertical">
                <SiteHeader bgColor="yellow" handleShowSignupModal={handleShowSignupModal} />
                <div className="flex vertical align-center justify-center pad-display mobile-no-pad-left mobile-no-pad-right v-gap-l yellow-bg" style={{flexBasis: "1px", flexGrow: "1"}}>
                    <div className="book-demo-form-section flex vertical align-center v-gap-s">
                        <Label content={`${demoRequestProcessed ? "We've received your demo request!" : "Book your demo!"}`} displayFont={true} semiBold={true} size="display" color={`${demoRequestProcessed ? "blue" : "black"}`} center={true} />
                        <Label content={`${demoRequestProcessed ? "A member of our team will reach out as soon as possible to schedule your demo." : "We'll give you an in-depth view of our platform and how we are the best option for excess management."}`} mono={true} size="m" line="l" center={true} />
                    </div>
                    {!demoRequestProcessed && <div className={`book-demo-form-section flex horizontal grey-bg round-m pad-xl ${errorShake ? "error-shake" : ""}`}>
                        <div className={`flex vertical width-100 v-gap-l ${demoRequestProcessing ? "dim" : ""}`}>
                            <LabelInput
                                label="Your Name"
                                type="text"
                                placeholder="Janet Park"
                                onWhite={false}
                                onGrey={true}
                                classes={errorsVisible && requestInfo?.fullName === '' ? "error" : ""}
                                onChange={handleFullNameChange}
                            />
                            <LabelInput
                                label="Work Email"
                                type="email"
                                placeholder="janet.park@atrium.com"
                                onWhite={false}
                                onGrey={true}
                                disallowSpaces={true}
                                classes={errorsVisible && (requestInfo?.email === '' || !validateEmail(requestInfo?.email)) ? "error" : ""}
                                onChange={handleEmailChange}
                            />
                            <LabelInput
                                label="Company Name"
                                type="text"
                                placeholder="Atrium"
                                onWhite={false}
                                onGrey={true}
                                classes={errorsVisible && requestInfo?.company === '' ? "error" : ""}
                                onChange={handleCompanyChange}
                            />
                            <div className="flex vertical v-gap-xs">
                                <Label content="How many employees work at your company?" size="s" semiBold="true" displayFont={true} />
                                <Select
                                    type="text"
                                    large={true}
                                    onWhite={false}
                                    onGrey={true}
                                    options={companySizes}
                                    classes={`${requestInfo?.companySize === "" ? "default" : ""}  ${(errorsVisible && requestInfo?.companySize === '') ? "error" : ""}`}
                                    onChange={handleCompanySizeChange}
                                />
                            </div>
                            <div className="flex vertical v-gap-xs">
                                <Label content="What is the book value of your excess inventory?" size="s" semiBold="true" displayFont={true} />
                                <Select
                                    type="text"
                                    large={true}
                                    onWhite={false}
                                    onGrey={true}
                                    options={excessSizes}
                                    classes={`${requestInfo?.excessSize === "" ? "default" : ""}  ${(errorsVisible && requestInfo?.excessSize === '') ? "error" : ""}`}
                                    onChange={handleExcessSizeChange}
                                />
                            </div>
                            <div className="flex vertical v-gap-xs">
                                <Label content="Anything else you'd like to share?" size="s" semiBold="true" displayFont={true} />
                                <TextArea
                                    type="text"
                                    onWhite={false}
                                    onChange={handleDetailsChange}
                                />
                            </div>
                            <div className="flex horizontal mobile-vertical mobile-align-end h-gap-xl v-gap-m space-between align-start">
                                <div id="recaptcha-container" className={`${errorsVisible && !captchaComplete ? "error" : ""}`}>
                                    <ReCAPTCHA
                                        sitekey={sitekey}
                                        onChange={handleCaptchaChange} style={{display: "flex"}} />
                                </div>
                                <button className="primary-button flex-1 poppins semi-bold size-m relative" onClick={handleSubmitContactRequest}>
                                    <Label content="Submit" size="m" semiBold="true" displayFont={true} center={true} />
                                </button>
                            </div>
                        </div>
                        <SimpleLoader loading={demoRequestProcessing} />
                    </div>}
                    <div className="book-demo-form-section flex horizontal space-between black-bg blue-shadow round-m pad-xl v-gap-s">
                        <div className="flex vertical v-gap-s">
                            <Label
                                displayFont={true}
                                content="Want to email us directly?"
                                size="l"
                                color="yellow"
                                bold={true}
                            />
                            <Button
                                onClick={() => window.location.href = "mailto:info@trymaterialize.com"}
                                label="info@trymaterialize.com"
                                bold={false}
                                icon={CursorClick}
                                iconSize={18}
                                onBrand={true}
                                classes="horizontal-reverse"
                                iconColor='black'
                            />
                        </div>
                        <div className="flex align-center pad-m no-pad-top no-pad-bot">
                            <Icon icon={EnvelopeOpenFill} size={48} color="var(--yellow)" />
                        </div>
                    </div>
                    <div className="pad-s"></div>
                </div>
                <SiteFooter bgColor="black" bumpForCookieFooter={local?.cookies === "unset"} handleShowSignupModal={handleShowSignupModal} />
            </div>
        </>
    );
};

export default ContactUs;