import './style/SiteHeader.css';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { } from '../../redux/localSlice';
import { } from '../../redux/temporarySlice';
import Section from './Section';
import Wordmark from '../../media/materialize-logo-wordmark-black.png';
import LinkButton from './LinkButton';

const SiteHeader = ({ bgColor = "yellow", handleShowSignupModal }) => {
    const local = useSelector((state) => state.local);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const [menuOpen, setMenuOpen] = useState(false);

    const handleSignup = () => {
        setMenuOpen(false);
        handleShowSignupModal();
    };
    
    return (
        <Section
            bg={bgColor}
            noPadTop={true}
            noPadBot={true}
            header={true}
            content={
                <div id="home" className={`${bgColor + "-bg"} flex horizontal flex-1 space-between pad-xl relative`}>
                    <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
                    <div id="menu" className="flex vertical align-center justify-center desktop-hide" onClick={() => setMenuOpen(!menuOpen)}>
                        <div className={`menu-bar ${menuOpen ? "open" : ""}`} />
                        <div className={`menu-bar ${menuOpen ? "open" : ""}`} />
                    </div>
                    <div id="menu-links" className={`flex horizontal mobile-vertical h-gap-l align-center ${!menuOpen ? "mobile-hide" :  "pad-m v-gap-s"} bump-header-mobile`}>
                        {!local?.inviteCode && (
                            <button className="ks-button width-100 center-text sf-mono uppercase size-s bold border-top" onClick={handleSignup}>Signup</button>
                        )}
                        {!local?.inviteCode && (
                            <LinkButton className="ks-button width-100 center-text sf-mono uppercase size-s bold" onClick={() => window.location.href = "https://app.trymaterialize.com"} link="https://app.trymaterialize.com">Login</LinkButton>
                        )}
                        {local?.inviteCode && (
                            <LinkButton className="ks-button width-100 center-text sf-mono uppercase size-s bold" onClick={() => window.location.href = `https://app.trymaterialize.com/login?code=${local?.inviteCode}`} link={`https://app.trymaterialize.com/login?code=${local?.inviteCode}`}>Dashboard</LinkButton>
                        )}
                        <LinkButton className="ks-button width-100 center-text sf-mono uppercase size-s bold" onClick={() => window.location.href = "https://shop.trymaterialize.com"} link='https://shop.trymaterialize.com'>Shop</LinkButton>
                        {(!local?.inviteCode && pathname !== "/book-demo") && (
                            <button className="ks-button header-cta width-100 center-text sf-mono uppercase size-s bold border-top" style={{minWidth: "108px"}} onClick={() => navigate("/book-demo")}>Book Demo</button>
                        )}
                    </div>
                </div>
            }
        />
    );
};

export default SiteHeader;