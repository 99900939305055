import React, { useState } from 'react';
import Label from './Label';
import Button from './Button';
import Divider from './Divider';
import CollapsableSection from './CollapsableSection';

const CookieSettings = ({ handleSetCookies, showCookieModal, classes = "", handleHideCookieModal }) => {
    const [analyticsChecked, setAnalyticsChecked] = useState(true);

    return (
        <div id="cookie-settings" className={`modal-overlay flex align-center justify-center ${classes}`}>
            <div className={`modal-content card flex vertical yes-pointer ${showCookieModal ? "fade-slide-in-d" : "fade-slide-out-d"}`}>
                <div className="flex horizontal align-center pad-m h-gap-xl space-between">
                    <Label content="Customize Consent Preferences" displayFont={true} bold="true" />
                    <Button label="Close" className="flex horizontal align-end justify-end" onClick={handleHideCookieModal} onBrand={true} />
                </div>
                <Divider />
                <div className="flex vertical overflow-y-scroll">
                    <div className="flex vertical v-gap-m pad-m no-pad-bot">
                        <Label content="We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below." mono={true} size="xs" line="s" />
                        <Label content="The cookies that are categorized as Necessary are stored on your browser as they are essential for enabling the basic functionalities of the site." mono={true} size="xs" line="s" />
                        <Label content="We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent." mono={true} size="xs" line="s" />
                        <Label content="You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience." mono={true} size="xs" line="s" />
                    </div>
                    <div className="flex vertical pad-m v-gap-s">
                        <CollapsableSection
                            label="Necessary"
                            classes="card-light"
                            content={
                                <div className="flex horizontal pad-s no-pad-top">
                                    <div className="flex white-bg round-s vertical pad-s v-gap-xs">
                                        <Label
                                            content="Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data."
                                            mono={true}
                                            size="xs"
                                            line="xs"
                                            bold={true}
                                            color="black"
                                        />
                                    </div>
                                </div>
                            }
                        />
                        <CollapsableSection
                            label="Functional"
                            classes="card-light"
                            content={
                                <div className="flex horizontal pad-s no-pad-top">
                                    <div className="flex horizontal white-bg round-s vertical pad-s v-gap-xs">
                                        <Label
                                            content="Functional cookies help perform certain functionalities like live support, collecting feedback, and other third-party features."
                                            mono={true}
                                            size="xs"
                                            line="xs"
                                            bold={true}
                                            color="black"
                                        />
                                        <input type="checkbox" className="flex checkbox" name="analytics" value="analytics" defaultChecked={true} />
                                    </div>
                                </div>
                            }
                        />
                        <CollapsableSection
                            label="Analytics"
                            classes="card-light hover-subtle"
                            content={
                                <div className="flex horizontal pad-s no-pad-top">
                                    <div className="flex horizontal white-bg round-s vertical pad-s v-gap-xs h-gap-m" onClick={null}>
                                        <Label
                                            content="Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc."
                                            mono={true}
                                            size="xs"
                                            line="xs"
                                            bold={true}
                                            color="black"
                                        />
                                        <input type="checkbox" className="flex checkbox" name="analytics" value="analytics" defaultChecked={true} checked={analyticsChecked} onChange={() => setAnalyticsChecked(!analyticsChecked)} />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>
                <Divider />
                <div className="flex flex-1 horizontal mobile-vertical pad-m h-gap-m v-gap-s">
                    <div className="flex flex-2 horizontal h-gap-m">
                        <Button label="Reject All" classes="white-bg" large={true} bold={false} secondary={true} onClick={() => handleSetCookies("essential")} flex={true} mobileFlex={true} />
                        <Button label="Save Preferences" classes="white-bg" large={true} bold={false} secondary={true} onClick={() => analyticsChecked ? handleSetCookies("all") : handleSetCookies("essential")} flex={true} mobileFlex={true} />
                    </div>
                    <Button label="Accept All" classes="yellow-bg" bold={false} large={true} onClick={() => handleSetCookies("all")} flex={true} mobileFlex={true} />
                </div>
            </div>
        </div>
    );
};

export default CookieSettings;