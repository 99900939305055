import './style/CookiesBanner.css';
import React from 'react';
import Label from './Label';
import Button from './Button';

const CookiesBanner = ({ handleShowCookieModal, handleSetCookies }) => {
    return (
        <div id="cookie-banner">
            <div className="blue-bg flex horizontal mobile-vertical pad-xl h-gap-xl v-gap-m space-between align-center">
                <div className="flex vertical v-gap-xs">
                    <Label content="We value your privacy" size="l" bold={true} line="l" />
                    <Label content='We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.' size="s" mono={true} line="s" />
                </div>
                <div className="flex horizontal h-gap-m mobile-width-100">
                    <Button label="Customize" secondary={true} bold={false} classes="blue-bg" large={true} onClick={handleShowCookieModal} mobileFlex={true} />
                    <Button label="Accept All" large={true} bold={false} classes="yellow-bg" onClick={() => handleSetCookies("all")} mobileFlex={true} />
                </div>
            </div>
        </div>
    );
};

export default CookiesBanner;