import './style/Input.css';
import React from 'react';

const Input = ({ customId, placeholder, type = "text", width100 = false, value, onKeyDown, onChange, inputRef, flex, large = false, onBrand, onWhite = true, onGrey = false, alignRight = false, onFocus = null, onBlur = null, classes = "", autocomplete = null, style = {}, disallowSpaces = false }) => {
    const handleKeyDown = (e) => {
        if (disallowSpaces && e.key === " ") {
            e.preventDefault();
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    };
    
    return (
        <input
            type={type}
            autoComplete={autocomplete}
            id={customId && customId}
            className={"input sf-mono size-m" + (flex ? " flex-1" : "") + (alignRight ? " align-right" : "") + (large ? " large" : " small") + (onBrand ? " on-brand" : "") + (onWhite ? " on-white" : "") + (onGrey ? " on-grey" : "") + (width100 ? " width-100" : "") + " " + classes}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            style={style}>
        </input>
    );
};

export default Input;