import React, { } from 'react';
import './style/Landing.css';
import Section from '../component/block/Section';
import Wordmark from '../media/materialize-logo-wordmark-black.png';
import TermsContent from '../component/block/TermsContent';
const Terms = () => {
    return (
        <div className="flex vertical full-screen overflow-y-scroll fade-in">
            <Section
                bg="yellow"
                noPadTop={true}
                noPadBot={true}
                header={true}
                content={
                    <>
                        <div id="home" className="yellow-bg flex horizontal flex-1 space-between pad-xl">
                            <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
                        </div>
                    </>
                }
            />
            <TermsContent />
        </div>
    );
};

export default Terms;