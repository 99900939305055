import './style/LabelInput.css';
import React, { useState, useRef } from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';
import Input from './Input';

const LabelInput = ({ onChange, index, type = "text", placeholder = "", label, value, valueType, onWhite = false, onGrey = false, classes = "", autocomplete = null, labelStyle={}, inputStyle={}, disallowSpaces = false }) => {

    const inputRef = useRef(null);
    const [results, setResults] = useState([]);

    const handleBlur = () => {
        setResults([]);
    };

    const handleChange = (e) => {
        if (valueType === "location-search" && inputRef?.current?.value) {
            setResults([inputRef?.current?.value]);
        } else {
            onChange(e);
        }
    };

    return (
        <div key={index} className="label-input flex vertical v-gap-xs flex-1 relative">
            <Label content={label} size="s" semiBold="true" displayFont={true} color={`${(onWhite || onGrey) ? "black" : "white"}`} style={labelStyle} />
            <Input
                placeholder={placeholder}
                autocomplete={autocomplete}
                type={type}
                inputRef={inputRef}
                large={true}
                value={value}
                onWhite={onWhite}
                onGrey={onGrey}
                onChange={(e) => handleChange(e)}
                onFocus={handleChange}
                onBlur={handleBlur}
                disallowSpaces={disallowSpaces}
                classes={classes}
                style={inputStyle} />
            {results?.length > 0 && <div className="keystone-label-input-results keystone-card pad-s">
                {results.map((result, index) => (
                    <Label key={index} index={index} content={result} size="m" mono={true} />
                ))}
            </div>}
        </div>
    );
};

export default LabelInput;