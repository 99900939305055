import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080' : 'https://api.trymaterialize.com';
};

export const signupRequest = createAsyncThunk(
    'landing/signupRequest',
    async ({ request }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/landing/signup_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(response.status);
            } else {
                return thunkAPI.fulfillWithValue(response.status);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const contactRequest = createAsyncThunk(
    'landing/contactRequest',
    async ( request, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/landing/signup_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                return thunkAPI.rejectWithValue(response.status);
            } else {
                return thunkAPI.fulfillWithValue(response.status);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const demoRequest = createAsyncThunk(
    'landing/demoRequest',
    async ( request, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/landing/signup_request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                return thunkAPI.rejectWithValue(response.status);
            } else {
                return thunkAPI.fulfillWithValue(response.status);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const temporarySlice = createSlice({
    name: 'temporary',
    initialState: {
        signupProcessing: false,
        signupProcessingError: null,
        signupProcessed: false,
        contactRequestProcessing: false,
        contactRequestError: null,
        contactRequestProcessed: false,
        demoRequestProcessing: false,
        demoRequestError: null,
        demoRequestProcessed: false
    },
    reducers: {
        setSignupProcessingError: (state, action) => {
            state.signupProcessingError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(signupRequest.pending, (state) => {
            state.signupProcessing = true;
            state.signupProcessingError = null;
        })
        .addCase(signupRequest.fulfilled, (state, action) => {
            state.signupProcessing = false;
            state.signupProcessed = true;
        })
        .addCase(signupRequest.rejected, (state, action) => {
            state.signupProcessing = false;
            state.signupProcessingError = action.payload;
        })
        .addCase(contactRequest.pending, (state) => {
            state.contactRequestProcessing = true;
            state.contactRequestError = null;
        })
        .addCase(contactRequest.fulfilled, (state, action) => {
            state.contactRequestProcessing = false;
            state.contactRequestProcessed = true;
        })
        .addCase(contactRequest.rejected, (state, action) => {
            state.contactRequestProcessing = false;
            state.contactRequestError = action.payload;
        })
        .addCase(demoRequest.pending, (state) => {
            state.demoRequestProcessing = true;
            state.demoRequestError = null;
        })
        .addCase(demoRequest.fulfilled, (state, action) => {
            state.demoRequestProcessing = false;
            state.demoRequestProcessed = true;
        })
        .addCase(demoRequest.rejected, (state, action) => {
            state.demoRequestProcessing = false;
            state.demoRequestError = action.payload;
        });
    }
});

export const { setSignupProcessingError } = temporarySlice.actions;

export default temporarySlice.reducer;